import { Injectable, OnDestroy } from '@angular/core';
import { AuthUtils } from '../utils/auth.utils';
import { AppConfigService } from '../../app-config.service';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class DataStorageService implements OnDestroy {

  private readonly TOKEN_STORED_KEY = 'roadrunner-ui-token';
  private readonly ACTIVE_CARRIER_STORED_KEY = 'roadrunner-ui-active-carrier';
  private readonly MOCK_PERMISSIONS_STORED_KEY = 'roadrunner-ui-mock-permissions';
  private readonly NONCE_STORED_KEY = 'roadrunner-ui-nonce';  
  private readonly ACCESSTOKEN_STORED_KEY = 'roadrunner-ui-access-token';

  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor(private appConfig: AppConfigService) {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getNonce() {
    return sessionStorage.getItem(
      this.prefixStoredNameWithEnvironment(this.NONCE_STORED_KEY));
  }

  public setNonce(nonce: string) {
    if (nonce) {
      sessionStorage.setItem(
        this.prefixStoredNameWithEnvironment(this.NONCE_STORED_KEY), nonce);
    }
  }

  public removeNonce() {
    sessionStorage.removeItem(
      this.prefixStoredNameWithEnvironment(this.NONCE_STORED_KEY));
  }

  public getToken() {
    if (AuthUtils.isTestOrDev()) {
      return AuthUtils.getDevToken();
    } else {
      const token = sessionStorage.getItem(
        this.prefixStoredNameWithEnvironment(this.TOKEN_STORED_KEY));
      return token !== AuthUtils.getDevToken() ? token : null;
    }
  }

  public setToken(token: string) {
    if (token) {
      sessionStorage.setItem(
        this.prefixStoredNameWithEnvironment(this.TOKEN_STORED_KEY), token);
    }
  }

  public getAccessToken() {
      return sessionStorage.getItem(
        this.prefixStoredNameWithEnvironment(this.ACCESSTOKEN_STORED_KEY));
  }

  public setAccessToken(accessToken: string) {
    if (accessToken) {
      sessionStorage.setItem(
        this.prefixStoredNameWithEnvironment(this.ACCESSTOKEN_STORED_KEY), accessToken);
    }
  }
  public removeToken() {
    sessionStorage.removeItem(
      this.prefixStoredNameWithEnvironment(this.TOKEN_STORED_KEY));
  }

  public getActiveCarrier() {
    return localStorage.getItem(
      this.prefixStoredNameWithEnvironment(this.ACTIVE_CARRIER_STORED_KEY));
  }

  public setActiveCarrier(carrierCode: string) {
    if (carrierCode) {
      const key = this.prefixStoredNameWithEnvironment(this.ACTIVE_CARRIER_STORED_KEY);
      localStorage.setItem(key, carrierCode);
      this.onSubject.next({ key: key, value: carrierCode });
    }
  }

  public removeActiveCarrier() {
    const key = this.prefixStoredNameWithEnvironment(this.ACTIVE_CARRIER_STORED_KEY);
    localStorage.removeItem(key);
    this.onSubject.next({ key: key, value: null });
  }

  public getMockPermissions() {
    return localStorage.getItem(
      this.prefixStoredNameWithEnvironment(this.MOCK_PERMISSIONS_STORED_KEY));
  }

  public setMockPermissions(carrierCode: string) {
    if (carrierCode) {
      localStorage.setItem(
        this.prefixStoredNameWithEnvironment(this.MOCK_PERMISSIONS_STORED_KEY), carrierCode);
    }
  }

  public removeMockPermissions() {
    localStorage.removeItem(
      this.prefixStoredNameWithEnvironment(this.MOCK_PERMISSIONS_STORED_KEY));
  }

  private prefixStoredNameWithEnvironment(key: string): string {
    return this.appConfig.config.environment + '_' + key;
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let data;
      try { data = JSON.parse(event.newValue); } catch (e) { data = event.newValue; }
      this.onSubject.next({ key: event.key, value: data });

      if (event.key === this.prefixStoredNameWithEnvironment(this.ACTIVE_CARRIER_STORED_KEY)
        && event.newValue !== event.oldValue) {
        window.location.reload();
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
